import CustomizeColumn from '@/components/Asset/CustomizeColumn/CustomizeColumn.vue'
import assetTypes from '@/store/AssetTypes/index.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { CustomizeColumn },
  data() {
    return {
      menu: false,
      selectedHeaderType: 'Asset',
      tab: null
    }
  },
  computed: {
    tabs() {
      return _.map(assetTypes, a => {
        return { name: a.graphTypeName, label: _.startCase(a.graphTypeName) }
      })
    },
    hasScannerAccess() {
      return _.some(
        assetTypes,
        at =>
          this.ability.can('create', at.graphTypeName) ||
          this.ability.can('update', at.graphTypeName)
      )
    },
    importChunkSize: {
      get() {
        return this.$store.state.settings.importChunkSize
      },
      set(newVal) {
        if (newVal <= 0) {
          newVal = 1
        }
        this.$store.commit('settings/setImportChunkSize', newVal)
      }
    },
    importThreadCount: {
      get() {
        return this.$store.state.settings.importThreadCount
      },
      set(newVal) {
        if (newVal <= 0) {
          newVal = 1
        }
        this.$store.commit('settings/setImportThreadCount', newVal)
      }
    },
    ...mapGetters({
      user: 'user',
      ability: 'user/ability',
      displayedHeaders: 'settings/getDisplayedHeaders'
    })
  },
  methods: {
    ...mapActions('auth', ['logout'])
  }
}
